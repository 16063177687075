import React from 'react';
import ContentPageLayout from '../components/ContentPageLayout';
import InternalInformationSystem from '../components/InternalInformationSystem';

const Page = () => (
  <ContentPageLayout title="Sistema interno de información">
    <InternalInformationSystem />
  </ContentPageLayout>
);

export default Page;
