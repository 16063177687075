import React from 'react';
import classnames from 'classnames';
import '../ContentPageLayout/ContentPageLayout.scss';
import './internalInformationSystem.scss';

const InternalInformationSystem = () => (
  <div className="InternalInformationSystem">
    <section className="ContentPageLayout-block">
      <p className="ContentPageLayout-text">
        <strong>GRUPO QUE BUENO</strong>, formado por NBQ FUND ONE, S.L.U. y NBQ TECHNOLOGY, S.A.U.
        (en adelante, la Entidad) ha implantado un{' '}
        <strong>Sistema Interno de Información (SIIF)</strong>, el cual integra los diferentes
        canales de información internos de la organización y se configura como un eje fundamental
        para el fomento de la cultura de actuación ética, responsable y sostenible, con el deber de
        supervisión, control y prevención en materia de cumplimiento normativo.
      </p>

      <p className="ContentPageLayout-text">
        El sistema constituye el cauce preferente y una herramienta de utilización obligatoria para
        canalizar diligentemente las comunicaciones sobre infracciones e incumplimientos normativos,
        con total protección de los informantes, sin riesgo de represalias y con las medidas de
        protección necesarias.
      </p>
      <p className={classnames('ContentPageLayout-text', 'ContentPageLayout-underline')}>
        Este canal no está habilitado para la gestión y tramitación de las quejas y reclamaciones de
        los clientes y dichas comunicaciones no serán atendidas. Las personas que deseen transmitir
        sugerencias, incidencias o reclamaciones pueden hacerlo a través del Servicio de Atención al
        Cliente (SAC).
      </p>
      <p className="ContentPageLayout-text">
        El SIIF se ha diseñado, configurado y está gestionado con las medidas y mecanismos
        necesarios de seguridad, confidencialidad, protección de datos y protección al informante.
        Para ello, contamos con la colaboración y soporte del experto externo{' '}
        <strong>BONET consulting</strong>, que aporta altos niveles de profesionalidad, experiencia,
        independencia, confidencialidad y cumplimiento del marco normativo aplicable.
      </p>
      <p className="ContentPageLayout-text">
        Por consiguiente, la Entidad ha dotado el SIIF de una serie de garantías, entre las que se
        encuentran el anonimato/anonimización, el adecuado registro, la conservación y no alteración
        de la información, la prevención de conflictos de interés, la protección del informante y la
        prohibición de represalias.
      </p>
      <p className="ContentPageLayout-text">
        Para conocer en detalle los principios de actuación y las garantías fundamentales que rigen
        el Sistema Interno de Información de la Entidad en virtud de los requisitos exigidos por la
        nueva normativa, puede ver y acceder a la{' '}
        <strong>
          Política General del Sistema Interno de Información y Defensa del Informante
        </strong>
        .
      </p>
      <h2 className="ContentPageLayout-subTitle">Canales de Información</h2>
      <p className="ContentPageLayout-text">
        La Entidad ha habilitado distintos canales internos de información que permiten la
        presentación de comunicaciones en formato escrito o verbal. A tal efecto, la Entidad cuenta
        con la configuración, diseño y soporte de un experto externo en aras de aportar los más
        altos niveles de profesionalidad, experiencia, independencia, confidencialidad, protección
        de datos y del informante, y otros ámbitos aplicables para este tipo de canales. En nuestra
        Política puede encontrar ampliada la información sobre las características técnicas de los
        canales. Los canales de nuestro SIIF son los siguientes:
      </p>
      <h2 className="ContentPageLayout-subTitle">Canal On-line/Digital</h2>
      <p className="ContentPageLayout-text">
        La Entidad pone a su disposición una herramienta on-line mediante la cual cualquier empleado
        o persona relacionada con la Entidad podrá presentar una comunicación por escrito a través
        del siguiente enlace:{' '}
        <a
          className="Link"
          href="https://www.corporate-line.com/cnormativo-quebueno"
          target="blank"
        >
          www.corporate-line.com/cnormativo-quebueno
        </a>
      </p>
      <p className="ContentPageLayout-text">
        Esta herramienta cuenta con medidas de seguridad que garantizan la protección de la
        información, la identidad del informante y la de aquellas personas afectadas por la misma,
        así como la confidencialidad y reserva de todo el proceso de gestión y tramitación de la
        comunicación.
      </p>
      <h2 className="ContentPageLayout-subTitle">Canal Presencial</h2>
      <p className="ContentPageLayout-text">
        Otra de las vías que la Entidad pone a disposición de sus empleados y aquellos terceros que
        se relacionan con ésta es el canal presencial, que permite la presentación de comunicaciones
        verbales mediante una reunión presencial o por videoconferencia.
      </p>
      <p className="ContentPageLayout-text">
        Al objeto de asegurar el debido cumplimiento de los compromisos y garantías recogidas en
        nuestra Política y el marco normativo de aplicación para este tipo de comunicaciones, la
        Entidad ha encomendado esta función de gestión de estas comunicaciones al experto externo
        BONET consulting, para garantizar la protección de la identidad del informante tanto en el
        proceso de solicitud de cita previa, en la presentación de una comunicación en formato
        presencial, como en el lugar de realización de la misma.
      </p>
      <p className="ContentPageLayout-text">
        Los profesionales de BONET son los responsables de la gestión de las solicitudes para
        presentar las comunicaciones a través de este canal, así como de la recogida y conservación
        de toda la información aportada en dicha solicitud. Tal y como establece la Ley, dichas
        reuniones quedarán documentadas mediante una grabación anonimizada, con el consentimiento
        previo del informante.
      </p>
      <p className="ContentPageLayout-text">
        Para poder hacer uso de este canal, debe solicitar <strong>cita previa</strong> a través de
        las siguientes vías de contacto:
      </p>
      <ul className="ContentPageLayout-list">
        <li className="ContentPageLayout-listItem">
          Teléfono: <img src="/img/phones.png" alt="phone" className="ContentPageLayout-icon" />
        </li>
        <div className="InternalInformationSystem-schedule">
          <p>El horario de atención al informante es el siguiente:</p>
          <ul className="ContentPageLayout-list">
            <li className="ContentPageLayout-listItem">
              De lunes a jueves de 8:30 a 14:00 y de 15:00 a 18:00 horas.
            </li>
            <li className="ContentPageLayout-listItem">Viernes de 8:30 a 14:30 horas.</li>
          </ul>
        </div>
        <li className="ContentPageLayout-listItem">
          E-mail:{' '}
          <a className="Link" href="mailto:quebueno@sistema-interno-informacion.com">
            quebueno@sistema-interno-informacion.com
          </a>
        </li>
      </ul>
      <p className="ContentPageLayout-text">
        Al objeto de garantizar las medidas de protección y seguridad necesarias de este tipo de
        comunicaciones, no se podrán realizar ni se registrarán comunicaciones presentadas
        directamente por teléfono o e-mail. Estas dos herramientas están habilitadas exclusivamente
        para coordinar con el personal técnico día, hora y lugar para realizar la comunicación en
        formato presencial.
      </p>
      <p className="ContentPageLayout-text">
        Agradecemos sigan las indicaciones descritas anteriormente para acceder y utilizar
        adecuadamente los canales oficiales habilitados por la Entidad para la presentación de
        comunicaciones y la aplicación de todos nuestros mecanismos de seguridad y protección
        necesarios.
      </p>
      <p className="ContentPageLayout-text">
        Para cualquier duda o consulta sobre el funcionamiento del Sistema Interno de Información de
        la Entidad, puede ponerse en contacto con el Responsable del Sistema a través del siguiente
        email:{' '}
        <a className="Link" href="mailto:responsablesiif@quebueno.es">
          responsablesiif@quebueno.es
        </a>
      </p>

      <p className="ContentPageLayout-text">
        Consulta nuestro{' '}
        <a
          class="Link"
          href="/files/SISTEMA INTERNO DE INFORMACION.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Política General del Sistema Interno de Información y Defensa del Informante
        </a>
        .
      </p>
    </section>
  </div>
);

export default InternalInformationSystem;
